import * as React from "react"
import Seo from "../components/seo";
import Layout from "../components/layout";

const PrivacyPage = () => (
    <Layout>
        <Seo title="Privacy"/>
        <div className="overflow-hidden lg:relative bg-white py-4 sm:py-8 lg:py-8">
            <div className="mx-auto max-w-md px-4 sm:max-w-xl sm:px-6 lg:max-w-5xl lg:px-8">
                <h1>
                    <span className="block text-3xl font-extrabold text-gray-900 sm:text-4xl">Evitat Privacy Policy</span>
                    <span className="block text-lg italic text-gray-900">Last updated: May 01, 2023</span>
                </h1>
                <p className="text-lg text-gray-900 max-w-full">The privacy of your data — and it is your data, not ours! — is a big deal to us. We limit who has access to your information and ensure that those who do are bound by contracts to keep your information availability restricted and safe.</p>
                <h2 className="text-gray-900 text-2xl font-semibold">Your Rights With Respect to Your Information</h2>
                <p className="text-lg text-gray-900 max-w-full">You may have heard about the General Data Protection Regulation (“GDPR”) in Europe. GDPR gives people under its protection certain rights with respect to their personal information collected by us on the Site. Accordingly, evitat australia pty ltd recognizes and will comply with GDPR and those rights, except as limited by applicable law. The rights under GDPR include:</p>
                <ul className="list-disc text-lg text-gray-900">
                    <li>Right of Access. This includes your right to access the personal information we gather about you, and your right to obtain information about the sharing, storage, security and processing of that information.</li>
                    <li>Right to Correction. This is your right to request correction of your personal information.</li>
                    <li>Right to Erasure. This is your right to request, subject to certain limitations under applicable law, that your personal information be erased from our possession (also known as the “Right to be forgotten”). However, if applicable law requires us to comply with your request to delete your information, the fulfilment of your request may prevent you from using evitat australia pty ltd services and may result in closing your account.</li>
                    <li>Right to Complain. You have the right to make a complaint regarding our handling of your personal information with the appropriate supervisory authority.</li>
                    <li>Right to Restrict Processing. This is your right to request restriction of how and why your personal information is used or processed.</li>
                    <li>Right to Object. This is your right, in certain situations, to object to how or why your personal information is processed.</li>
                    <li>Right to Portability. This is your right to receive the personal information we have about you and the right to transmit it to another party.</li>
                    <li>Right to not be subject to Automated Decision-Making. This is your right to object and prevent any decision that could have a legal, or similarly significant, effect on you from being made solely based on automated processes. This right is limited, however, if the decision is necessary for performance of any contract between you and us, is allowed by applicable European law, or is based on your explicit consent.</li>
                    <li>Many of these rights can be exercised by signing in and directly updating your account information. If you have questions about exercising these rights or need assistance, please contact us at <a className="text-green-600 hover:text-green-500" href="mailto:support@evitat.com.au">support@evitat.com.au</a>.</li>
                    <li>As part of the services we provide, and only to the extent necessary, we may use certain third party processors to process some or all of your personal information. For identification of these processors, and where they are located, please see our Sub processor listing. We have signed appropriate data processing contracts that comply with GDPR with each processor.</li>
                </ul>
                <h2 className="text-gray-900 text-2xl font-semibold">Marketing</h2>
                <p className="text-lg text-gray-900 max-w-full">We will only send you marketing where you have agreed to this. We may use information we hold about you to show you relevant advertising on third party sites (eg Facebook, Google, etc). If you don’t want to be shown targeted advertising messages from us, some third party sites allow you to request not to see further messages from specific advertisers on that site. If you want to stop receiving marketing direct from us, including targeted advertising messages on third party sites, you can do so by contacting us at <a className="text-green-600 hover:text-green-500" href="mailto:support@evitat.com.au">support@evitat.com.au</a></p>
                <h2 className="text-gray-900 text-2xl font-semibold">Law enforcement</h2>
                <p className="text-lg text-gray-900 max-w-full">evitat australia pty ltd won’t hand your data over to law enforcement unless a court order says we have to. We flat-out reject requests from local and federal law enforcement when they seek data without a court order. And unless we're legally prevented from it, we’ll always inform you when such requests are made.</p>
                <h2 className="text-gray-900 text-2xl font-semibold">Security & Encryption</h2>
                <p className="text-lg text-gray-900 max-w-full">All data is encrypted via SSL/TLS when transmitted from our servers to your browser. The database backups are also encrypted. Data isn’t encrypted while it's live in our database (since it needs to be ready to send to you when you need it), but we go to great lengths to secure your data at rest.</p>
                <h2 className="text-gray-900 text-2xl font-semibold">Deleted data</h2>
                <p className="text-lg text-gray-900 max-w-full">When you cancel your account, we'll ensure that nothing is stored on our servers past 30 days. Anything you delete on your account while it's active will also be purged within 30 days, you can do so by contacting us at <a className="text-green-600 hover:text-green-500" href="mailto:support@evitat.com.au">support@evitat.com.au</a></p>

                <h2 className="text-gray-900 text-2xl font-semibold">Location of Site and Data</h2>
                <p className="text-lg text-gray-900 max-w-full">This Site is operated in Australia. If you are located in the European Union or elsewhere outside of Australia, please be aware that any information you provide to us will be transferred to Australia. By using our Site, participating in any of our services and/or providing us with your information, you consent to this transfer.</p>

                <h2 className="text-gray-900 text-2xl font-semibold">Changes & questions</h2>
                <p className="text-lg text-gray-900 max-w-full">From time to time, we may make changes to the privacy policy. This may be in relation to changes in the law, best practice or changes to the evitat service. These changes will be reflected in this statement, so you should check here regularly Questions about this privacy policy? Please get in touch, and we’ll be happy to answer them!</p>
            </div>
        </div>
    </Layout>
)

export default PrivacyPage
